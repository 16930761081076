<template>
  <div id="pageHome">
    <app-navbar></app-navbar>
    <app-slider-component
      :slides="getPhotos()"
      :time="6000"
      :hasArrows="false"
      :havelink="true"
      :links="links"
    ></app-slider-component>
    <div class="container-new">
      <div class="yellowTwoBallonHorizont">
        <img
          v-parallax="0.1"
          class="img-fluid"
          src="@/assets/yellowTwoBallonHorizont.png"
          alt="yellowTwoBallonHorizont"
        />
      </div>
      <div class="whiteBalls">
        <img
          v-parallax="0.2"
          class="img-fluid"
          src="@/assets/whiteBalls.png"
          alt="whiteBalls"
        />
      </div>
      <!--      <app-recommendation></app-recommendation>-->
    </div>
    <div class="container-new">
      <div class="crossBallone">
        <img
          v-parallax="0.3"
          class="img-fluid"
          src="@/assets/crossRedBallone.png"
          alt="whiteBallone"
        />
      </div>
    </div>
    <app-news></app-news>
    <div class="container-new">
      <!--      <div class="yellowTwoBallon">-->
      <!--        <img v-parallax="0.1" class="img-fluid" src="@/assets/yellowTwoBallon.png" alt="yellowTwoBallon">-->
      <!--      </div>-->
      <div class="yellowSquareBallone">
        <img
          v-parallax="0.2"
          class="img-fluid"
          src="@/assets/yellowSquareBallone.png"
          alt="yellowSquareBallone"
        />
      </div>
      <app-festival-video class="pt-lg-4"></app-festival-video>
    </div>
    <div class="container-new">
      <app-main-sponsor></app-main-sponsor>
      <div class="redBallone">
        <img
          v-parallax="0.1"
          class="img-fluid"
          src="@/assets/redBallone.png"
          alt="redBallone"
        />
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import festivalVideo from "@/components/base/pageHome/festivalVideo";
// import Recommendation from "@/components/base/pageHome/Recommendation";
import news from "@/components/base/pageHome/news";
import mainSponsor from "@/components/base/pageHome/mainSponsor";
import navbar from "@/components/base/navbar";
import maratonFooter from "@/components/base/footer";
import SliderComponent from "@/components/base/pageHome/SliderComponent";

export default {
  name: "pageHome",
  components: {
    "app-main-sponsor": mainSponsor,
    // 'app-recommendation': Recommendation,
    "app-news": news,
    "app-festival-video": festivalVideo,
    "app-slider-component": SliderComponent,
    "app-navbar": navbar,
    // 'app-subscribe': Subscribe,
    "app-footer": maratonFooter,
  },
  data() {
    return {
      sliderPhotoCz: [
        "/slider/cz/2025/slider1.jpg",
        "/slider/cz/2025/slider2.jpg",
        "/slider/cz/2025/slider3.jpg",
      ],
      sliderPhotoSmallCz: [
        "/slider/cz/2025/slider1.jpg",
        "/slider/cz/2025/slider2.jpg",
        "/slider/cz/2025/slider3.jpg",
      ],
      sliderPhotoEn: [
        "/slider/cz/2025/slider1.jpg",
        "/slider/cz/2025/slider2.jpg",
        "/slider/cz/2025/slider3.jpg",
      ],
      links: [
        // "https://goout.net/cs/omar-sosa-afrocubano/szenukv/",
        // "https://goout.net/cs/balkan-night-dubioza-kolektiv/szhnukv/",
        // "https://goout.net/cs/world-music-puuluup%2Bomar-sosa-and-stracho-temelkovski/szpnukv/"
      ],
    };
  },
  computed: {
    isActiveCs: function() {
      return !(localStorage.getItem("lang") === "en");
    },
    isActiveEn: function() {
      return localStorage.getItem("lang") === "en";
    },
  },
  methods: {
    getWidth: function() {
      return window.innerWidth;
    },
    getPhotos: function() {
      if (window.localStorage.getItem("lang") === "en") {
        return this.sliderPhotoEn;
      } else {
        if (this.getWidth() > 800) {
          return this.sliderPhotoCz;
        } else {
          return this.sliderPhotoSmallCz;
        }
      }
    },
  },
};
</script>

<style scoped>
h1 {
  padding-top: 1em;
  font-size: 3.5em;
  color: black;
  font-family: "Transcript Pro";
}

#pageHome {
  background-color: #FFCE6D;
}

.container-new {
  position: relative;
}

/**
absolute -- components
 */
.yellowTwoBallonHorizont {
  position: absolute;
  width: 10%;
  top: 20vh;
  left: 6%;
}

.whiteBalls {
  position: absolute;
  z-index: 1;
  width: 10%;
  top: 60vh;
  left: 7%;
}

.redBallone {
  position: absolute;
  top: 50vh;
  width: 10%;
  left: 6%;
}

.crossBallone {
  width: 11%;
  z-index: 1;
  position: absolute;
  text-align: end;
  top: 1vh;
  right: 3%;
}

.yellowTwoBallon {
  position: absolute;
  top: 40vh;
  z-index: 1;
  left: 5%;
  width: 6%;
}

.yellowSquareBallone {
  position: absolute;
  text-align: end;
  top: 50vh;
  z-index: 1;
  width: 7%;
  right: 4%;
}

@media (max-width: 740px) {
  .yellowSquareBallone,
  .yellowTwoBallon,
  .crossBallone,
  .redBallone,
  .whiteBalls,
  .yellowTwoBallonHorizont {
    display: none;
  }
}
</style>
